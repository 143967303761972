import { EU_COUNTRIES, ShippableCountry } from '@lib/machine-parts/storefront/utils';
import { CheckoutNote } from '../interfaces/note';

export function formatNote({ invoice, note, vat }: CheckoutNote, ShippableCountry?: ShippableCountry) {
    const data = [
        ...(invoice ? [`Invoice email: ${invoice}`] : []),
        ...(note ? [`Customer note: ${note}`] : ''),
        ...(vat ? [`VAT Number: ${vat}`] : ''),
    ];
    if (ShippableCountry) {
        if (ShippableCountry === 'NL') {
            data.push(
                'VAT Regime: Reverse charge mechanism applicable, VAT liability shifted to the customer according to article 12 (3) WET OB. VAT 0 %.',
            );
        } else if (ShippableCountry && EU_COUNTRIES.some((eu_country) => ShippableCountry === eu_country)) {
            data.push(
                'VAT Regime: Intra-community supply is tax-exempt according to Art. 138 (1) Directive 2006/112/EC and subject to VAT reverse charge by customer',
            );
        } else {
            data.push('VAT Regime: VAT 0 %, Export of goods');
        }
    }
    if (!data.length) return undefined;
    return data.join('\n');
}
