import axios from 'axios';

import { CartDeliveryGroup } from '@shopify/hydrogen-react/storefront-api-types';
import { useMutation } from '@tanstack/react-query';
import { InternationalizationProps } from '@lib/machine-parts/storefront/utils';

const url = '/api/cart/delivery-groups';

type MakeDeliveryGroupsResponse = {
    deliveryGroups: Omit<CartDeliveryGroup, 'cartLines'>[];
    errors: string | undefined;
};

interface UseGetDeliveryGroupsQueryProps extends Pick<InternationalizationProps, 'locale'> {
    onSuccess?: (data: Omit<CartDeliveryGroup, 'cartLines'>[]) => void;
    onError?: (error?: string) => void;
}

export function useGetDeliveryGroupsMutation({ locale, onSuccess, onError }: UseGetDeliveryGroupsQueryProps) {
    return useMutation(
        ['delivery-groups'],
        async ({ cartId }: { cartId?: string }): Promise<Omit<CartDeliveryGroup, 'cartLines'>[]> => {
            if (!cartId) return [];

            const { data } = await axios.post<MakeDeliveryGroupsResponse>(url, {
                cartId,
                country: locale,
            });

            if (data.errors) {
                throw new Error(data.errors);
            }

            return data.deliveryGroups;
        },
        {
            onSuccess,
            onError,
        },
    );
}
