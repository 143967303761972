import { CartWithActions, useCart } from '@shopify/hydrogen-react';
import { CurrencyCode } from '@lib/machine-parts/types/graphql/storefront';

export interface ExtendedCartWithActions extends CartWithActions {
    discountAllocations: {
        discountedAmount: {
            amount: string;
            currencyCode: CurrencyCode;
        };
    }[];
}

export const useExtendedCart = useCart as () => ExtendedCartWithActions;
