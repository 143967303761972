'use client';

import { ReactNode } from 'react';

import { ShippableCountry, SupportedLanguage } from '@lib/machine-parts/storefront/utils';
import { CartProvider as ShopifyReactCartProvider } from '@shopify/hydrogen-react';

import { CartUIProvider } from './CartUIProvider';
import { CART_FRAGMENT } from '../constants/CartFragment';
import { CountryCode } from '@shopify/hydrogen-react/storefront-api-types';

type Props = {
    children: ReactNode;
    numCartLines?: number;
    countryCode?: ShippableCountry;
    languageCode?: SupportedLanguage;
};

/**
 * A client component that creates a cart object and provides callbacks that can be accessed by any descendent component using the `useCart` hook and related hooks
 */
export function CartProvider({ children, ...props }: Props) {
    return (
        <CartUIProvider>
            <Provider {...props}>{children}</Provider>
        </CartUIProvider>
    );
}

function Provider({ children, numCartLines, countryCode }: Props) {
    return (
        <ShopifyReactCartProvider
            countryCode={countryCode as CountryCode}
            numCartLines={numCartLines}
            cartFragment={CART_FRAGMENT}
        >
            {children}
        </ShopifyReactCartProvider>
    );
}
