export enum CheckoutAction {
    GET = 'get',
    CREATE = 'create',
    REMOVE_LINE_ITEMS = 'removeLineItems',
    UPDATE_LINE_ITEMS = 'updateLineItem',
    REPLACE_LINE_ITEMS = 'replaceLineItems',
    UPDATE_ATTRIBUTES = 'updateAttributes',
    ASSOCIATE_CUSTOMER = 'associateCustomer',
    DISASSOCIATE_CUSTOMER = 'disassociateCustomer',
    UPDATE_EMAIL = 'updateEmail',
    ADD_DISCOUNT_CODE = 'addDiscountCode',
    REMOVE_DISCOUNT_CODE = 'removeDiscountCode',
    ADD_GIFT_CARD = 'addGiftCard',
    REMOVE_GIFT_CARD = 'removeGiftCard',
    UDPATE_SHIPPING_ADDRESS = 'updateShippingAddress',
    UPDATE_BILLING_ADDRESS = 'updateBillingAddress',
    GET_AVAILABLE_SHIPPING_RATES = 'getAvailableShippingRates',
    UPDATE_SHIPPING_LINE = 'updateShippingLine',
    CLEAR = 'clear',
    COMPLETE = 'complete',
}
