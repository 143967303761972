import { DefaultVatForm } from '@lib/machine-parts/storefront/account/utils';
import { DefaultPhoneForm } from './info';

export enum CheckoutStepName {
    INIT = 'not_started',
    INFO = 'information',
    PAYMENT_METHOD = 'payment_method',
    SHIPPING_METHOD = 'shipping_method',
    REVIEW_ORDER = 'review_order',
}

export enum PaymentMethod {
    DIRECT = 'direct',
    DEPOSIT = 'deposit',
}

export interface UserInfoForm {
    email: string;
    firstName: string;
    lastName: string;
    phone: DefaultPhoneForm;
    vat: DefaultVatForm;
}
