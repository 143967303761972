'use client';

import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export const CartContext = createContext<{
    isCartOpen: boolean;
    openCart: () => void;
    closeCart: () => void;
    toggleCart: () => void;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);

type Props = {
    children: ReactNode;
};

/**
 * A client component that defines the behavior that occurs when a user is interacting with a cart (for example, opening or closing it)
 */
export function CartUIProvider({ children }: Props) {
    const [open, setOpen] = useState(false);

    const openCart = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const closeCart = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const toggleCart = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    const contextValue = useMemo(() => {
        return {
            isCartOpen: open,
            openCart,
            closeCart,
            toggleCart,
        };
    }, [open, openCart, closeCart, toggleCart]);

    return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
}

export function useCartUI() {
    return useContext(CartContext);
}
