import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CartWithDeliveryGroupsQueryVariables = Types.Exact<{
    cartId: Types.Scalars['ID'];
    country: Types.CountryCode;
}>;

export type CartWithDeliveryGroupsQuery = {
    __typename?: 'QueryRoot';
    cart?:
        | {
              __typename?: 'Cart';
              deliveryGroups: {
                  __typename?: 'CartDeliveryGroupConnection';
                  edges: Array<{
                      __typename?: 'CartDeliveryGroupEdge';
                      node: {
                          __typename?: 'CartDeliveryGroup';
                          id: string;
                          groupType: Types.CartDeliveryGroupType;
                          deliveryOptions: Array<{
                              __typename?: 'CartDeliveryOption';
                              title?: string | undefined;
                              handle: string;
                              deliveryMethodType: Types.DeliveryMethodType;
                              estimatedCost: {
                                  __typename?: 'MoneyV2';
                                  amount: string;
                                  currencyCode: Types.CurrencyCode;
                              };
                          }>;
                          deliveryAddress: {
                              __typename?: 'MailingAddress';
                              id: string;
                              address1?: string | undefined;
                              address2?: string | undefined;
                              city?: string | undefined;
                              company?: string | undefined;
                              country?: string | undefined;
                              countryCodeV2?: Types.CountryCode | undefined;
                              firstName?: string | undefined;
                              formatted: Array<string>;
                              formattedArea?: string | undefined;
                              lastName?: string | undefined;
                              latitude?: number | undefined;
                              longitude?: number | undefined;
                              name?: string | undefined;
                              phone?: string | undefined;
                              province?: string | undefined;
                              provinceCode?: string | undefined;
                              zip?: string | undefined;
                          };
                          selectedDeliveryOption?:
                              | {
                                    __typename?: 'CartDeliveryOption';
                                    title?: string | undefined;
                                    handle: string;
                                    deliveryMethodType: Types.DeliveryMethodType;
                                    estimatedCost: {
                                        __typename?: 'MoneyV2';
                                        amount: string;
                                        currencyCode: Types.CurrencyCode;
                                    };
                                }
                              | undefined;
                      };
                  }>;
              };
          }
        | undefined;
};

export type DeliveryGroupsFragment = {
    __typename?: 'Cart';
    deliveryGroups: {
        __typename?: 'CartDeliveryGroupConnection';
        edges: Array<{
            __typename?: 'CartDeliveryGroupEdge';
            node: {
                __typename?: 'CartDeliveryGroup';
                id: string;
                groupType: Types.CartDeliveryGroupType;
                deliveryOptions: Array<{
                    __typename?: 'CartDeliveryOption';
                    title?: string | undefined;
                    handle: string;
                    deliveryMethodType: Types.DeliveryMethodType;
                    estimatedCost: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                }>;
                deliveryAddress: {
                    __typename?: 'MailingAddress';
                    id: string;
                    address1?: string | undefined;
                    address2?: string | undefined;
                    city?: string | undefined;
                    company?: string | undefined;
                    country?: string | undefined;
                    countryCodeV2?: Types.CountryCode | undefined;
                    firstName?: string | undefined;
                    formatted: Array<string>;
                    formattedArea?: string | undefined;
                    lastName?: string | undefined;
                    latitude?: number | undefined;
                    longitude?: number | undefined;
                    name?: string | undefined;
                    phone?: string | undefined;
                    province?: string | undefined;
                    provinceCode?: string | undefined;
                    zip?: string | undefined;
                };
                selectedDeliveryOption?:
                    | {
                          __typename?: 'CartDeliveryOption';
                          title?: string | undefined;
                          handle: string;
                          deliveryMethodType: Types.DeliveryMethodType;
                          estimatedCost: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                      }
                    | undefined;
            };
        }>;
    };
};

export const DeliveryGroupsFragmentDoc = gql`
    fragment DeliveryGroups on Cart {
        deliveryGroups(first: 10, withCarrierRates: true) {
            edges {
                node {
                    id
                    deliveryOptions {
                        title
                        handle
                        estimatedCost {
                            amount
                            currencyCode
                        }
                        deliveryMethodType
                    }
                    deliveryAddress {
                        id
                        address1
                        address2
                        city
                        company
                        country
                        countryCodeV2
                        firstName
                        formatted
                        formattedArea
                        lastName
                        latitude
                        longitude
                        name
                        phone
                        province
                        provinceCode
                        zip
                    }
                    selectedDeliveryOption {
                        title
                        handle
                        estimatedCost {
                            amount
                            currencyCode
                        }
                        deliveryMethodType
                    }
                    groupType
                }
            }
        }
    }
`;
export const CartWithDeliveryGroupsDocument = gql`
    query CartWithDeliveryGroups($cartId: ID!, $country: CountryCode!) @inContext(country: $country) {
        cart(id: $cartId) {
            ...DeliveryGroups @defer
        }
    }
    ${DeliveryGroupsFragmentDoc}
`;

/**
 * __useCartWithDeliveryGroupsQuery__
 *
 * To run a query within a React component, call `useCartWithDeliveryGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartWithDeliveryGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartWithDeliveryGroupsQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCartWithDeliveryGroupsQuery(
    baseOptions: Apollo.QueryHookOptions<CartWithDeliveryGroupsQuery, CartWithDeliveryGroupsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CartWithDeliveryGroupsQuery, CartWithDeliveryGroupsQueryVariables>(
        CartWithDeliveryGroupsDocument,
        options,
    );
}
export function useCartWithDeliveryGroupsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CartWithDeliveryGroupsQuery, CartWithDeliveryGroupsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CartWithDeliveryGroupsQuery, CartWithDeliveryGroupsQueryVariables>(
        CartWithDeliveryGroupsDocument,
        options,
    );
}
export type CartWithDeliveryGroupsQueryHookResult = ReturnType<typeof useCartWithDeliveryGroupsQuery>;
export type CartWithDeliveryGroupsLazyQueryHookResult = ReturnType<typeof useCartWithDeliveryGroupsLazyQuery>;
export type CartWithDeliveryGroupsQueryResult = Apollo.QueryResult<
    CartWithDeliveryGroupsQuery,
    CartWithDeliveryGroupsQueryVariables
>;
