import { extractId } from '@lib/machine-parts/storefront/utils';
import { CartWithActions } from '@shopify/hydrogen-react/dist/types/cart-types';

export function formatLineItemForCheckout(lineItems: CartWithActions['lines']) {
    return (
        lineItems
            ?.filter((item) => item?.id && item.quantity)
            .map((item) => ({ variantId: extractId(item?.merchandise?.id) ?? '', quantity: item?.quantity ?? 1 })) ?? []
    );
}
