import { object, string } from 'yup';

interface GetNotesSectionResolverProps {
    invoice?: { invalidFormat?: string };
    note?: { max?: string };
}

export function getNotesSectionResolver(t: GetNotesSectionResolverProps = {}) {
    return object()
        .shape({
            invoice: string(),
            note: string().max(1000, t.note),
        })
        .noUnknown()
        .strict()
        .required();
}
