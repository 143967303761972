'use client';
import { omit } from 'lodash';
import { useRouter } from 'next/navigation';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';

import { UseCustomerDataResult } from '@lib/machine-parts/storefront/account/data-access';
import { areEqualAddresses, toInputMaybeAddress } from '@lib/machine-parts/storefront/account/utils';
import { CheckoutNote, formatNote, PaymentMethod } from '@lib/machine-parts/storefront/checkout/utils';
import {
    extractId,
    formatPhone,
    formatRoute,
    LocalStorageKey,
    Route,
    useLocalisation,
} from '@lib/machine-parts/storefront/utils';
import {
    CountryCode as AdminCountryCode,
    CurrencyCode as AdminCurrencyCode,
    DraftOrderAppliedDiscountType,
    DraftOrderInput,
    ShippingLineInput,
} from '@lib/machine-parts/types/graphql/admin';
import { MailingAddress } from '@shopify/hydrogen-react/storefront-api-types';

import { useCreateOrderMutation } from './cart/createOrderMutation';
import { useSelectDeliveryOptionsMutation } from './cart/selectDeliveryOptionsMutation';
import { useGetDeliveryGroupsMutation } from './cart/useGetDeliveryGroupsMutation';
import { CheckoutContext, UseCheckoutProviderProps } from './interfaces/CheckoutContext';
import { useExtendedCart } from './interfaces/ExtendedCartWithActions';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const _CheckoutContext = createContext<CheckoutContext>(undefined!);

export function CheckoutProviderClient({ children, customer }: PropsWithChildren<UseCheckoutProviderProps>) {
    const { push: navigate, refresh } = useRouter();
    const { locale } = useLocalisation();
    const {
        id: cartId,
        status,
        buyerIdentity,
        lines = [],
        discountCodes,
        discountAllocations,
        linesRemove,
        cartCreate,
        discountCodesUpdate,
        buyerIdentityUpdate,
    } = useExtendedCart();

    // States
    const [customerInfo, setCustomerInfo] = useState<UseCustomerDataResult | undefined>(customer);
    const [shippingAddressOptions, setShippingAddressOptions] = useState<Omit<MailingAddress, 'formatted'>[]>(
        customer.addresses ?? [],
    );
    const [billingAddressOptions, setBillingAddressOptions] = useState<
        (Omit<MailingAddress, 'formatted' | 'id'> & { id?: string })[]
    >(customer.billingAddresses ?? []);
    const [shippingAddress, setShippingAddress] = useState<Omit<MailingAddress, 'formatted' | 'id'> | undefined>(
        customer.addresses?.find(({ id }) => extractId(id) === customer.defaultShippingAddressId) ??
            customer.addresses?.at(0),
    );
    const [billingAddress, setBillingAddress] = useState<
        (Omit<MailingAddress, 'formatted' | 'id'> & { id?: string }) | undefined
    >(customer.billingAddresses?.find((address) => address.default) ?? customer.billingAddresses?.at(0));
    const [shippingAddressChanged, setShippingAddressChanged] = useState(true);
    const [note, setNote] = useState<CheckoutNote>({ invoice: customer?.email ?? '', note: '' });
    const [shippingMethod, setShippingMethod] = useState<string | undefined>();
    const [discountCode, setDiscountCode] = useState<string | undefined>();
    const [discountCodeError, setDiscountCodeError] = useState<boolean>(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.DEPOSIT);
    const [checkoutError, setCheckoutError] = useState(false);
    const [changingCurrency, setChangingCurrency] = useState(false);

    // Loading states
    const [loading, setLoading] = useState(false);
    const [loadingShippingAddress, setLoadingShippingAddress] = useState(false);
    const [loadingShippingMethods, setLoadingShippingMethods] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    //#region Mutations
    const { mutateAsync: selectDeliveryOptions, isLoading: selectingDeliveryOption } = useSelectDeliveryOptionsMutation(
        {
            onError: (error) => {
                console.error(`Failed to select a shipping method: ${error}`);
                setShippingMethod(undefined);
                setLoadingShippingMethods(false);
                setLoading(false);

                Sentry.withScope(function (scope) {
                    scope.setLevel('warning');
                    Sentry.captureException(`Failed to select a shipping method: ${error}`);
                });
            },
            onSuccess: (shippingRateHandle) => {
                setShippingMethod(shippingRateHandle);
                refetchDeliveryGroups();
                setLoading(false);
            },
        },
    );
    const refetchAndAutoSelect = useRef(true);
    const {
        mutateAsync: fetchDeliveryGroups,
        data: deliveryGroups,
        isLoading: fetchingDeliveryGroups,
    } = useGetDeliveryGroupsMutation({
        locale,
        onError: (error) => {
            console.error(`Delivery groups query failed: ${error}`);

            Sentry.withScope(function (scope) {
                scope.setLevel('warning');
                Sentry.captureException(`Failed to fetch shipping methods: ${error}`);
            });
        },
        onSuccess: async (data) => {
            if (refetchAndAutoSelect.current && data?.length) {
                const deliveryGroupId = data.at(0)?.id;
                const shippingRateHandle = data
                    .flatMap((group) => group.deliveryOptions)
                    ?.sort((a, b) => (Number(a.estimatedCost.amount) < Number(b.estimatedCost.amount) ? -1 : 1))
                    ?.at(0)?.handle;

                if (shippingRateHandle && deliveryGroupId) {
                    refetchAndAutoSelect.current = false;
                    await updateShippingMethod(shippingRateHandle, deliveryGroupId);
                }
            } else {
                console.warn('No delivery groups available');
            }
        },
    });
    const { mutateAsync: mutateDraftOrder } = useCreateOrderMutation({
        onError: (error) => {
            setCheckoutError(true);
            console.error(`Failed to create order: ${error}`);
            setLoadingShippingMethods(false);
            setLoading(false);
            setLoadingComplete(false);

            Sentry.withScope(function (scope) {
                scope.setLevel('warning');
                Sentry.captureException(`Failed to create order: ${error}`);
            });
        },
        onSuccess: (result) => {
            const extractedOrderId = extractId(result.orderId)?.split('/').at(-1);
            if (!extractedOrderId) throw Error(`Order id invalid (${result.orderId})`);
            navigate(
                formatRoute(Route.CHECKOUT_ORDER_CONFIRMATION, {
                    context: { orderId: extractedOrderId },
                    params: { email: customerInfo?.email ?? 'unknown' },
                }),
            );
            refresh();
            setCheckoutError(false);
            linesRemove(lines.reduce<string[]>((lines, line) => (line?.id ? [...lines, line.id] : lines), []));
            if (!customer.email) {
                // if not logged in, reset checkout states
                clearCheckout();
            }
        },
    });
    //#endregion

    //#region Helper Methods
    const refetchDeliveryGroups = useCallback(
        async (autoSelect = false) => {
            Sentry.addBreadcrumb({
                message: 'Fetching delivery groups',
                category: 'checkout_provider',
                data: { cartId, autoSelect },
            });

            setLoadingShippingMethods(true);
            if (autoSelect) refetchAndAutoSelect.current = true;

            return fetchDeliveryGroups({
                cartId,
            });
        },
        [cartId, fetchDeliveryGroups],
    );

    const updateCustomerAddressOptions = useCallback((customer: UseCustomerDataResult) => {
        setShippingAddressOptions(customer.addresses ?? []);
        setBillingAddressOptions(customer.billingAddresses ?? []);
    }, []);

    //#endregion

    //#region CheckoutContext Methods
    const updateShippingMethod: CheckoutContext['updateShippingMethod'] = useCallback(
        async (shippingRateHandle, deliveryGroupId) => {
            if (!cartId) return;

            Sentry.addBreadcrumb({
                message: 'Selecting shipping shipping method',
                category: 'checkout_provider',
                data: {
                    shippingRateHandle,
                    deliveryGroupId,
                },
            });

            setLoading(true);
            setLoadingShippingMethods(true);
            await selectDeliveryOptions({
                cartId,
                selectedDeliveryOptions: [{ deliveryGroupId, deliveryOptionHandle: shippingRateHandle }],
            });
        },
        [cartId, selectDeliveryOptions],
    );

    const updateDiscountCode: CheckoutContext['updateDiscountCode'] = useCallback(
        async (code: string | null) => {
            Sentry.addBreadcrumb({
                message: 'Updating discount code',
                category: 'checkout_provider',
                data: {
                    code,
                },
            });

            discountCodesUpdate(code ? [code] : []);

            if (!code) {
                setDiscountCodeError(false);
                setDiscountCode(undefined);
                localStorage.removeItem(LocalStorageKey.DISCOUNT_CODE);
            }
        },
        [discountCodesUpdate],
    );

    const updateCustomerInfo: CheckoutContext['updateCustomerInfo'] = useCallback(
        (payload) => {
            let customerData: CheckoutContext['customerInfo'] = customerInfo;
            if (payload.email && payload.email !== customerInfo?.email) {
                customerData = { ...customerData, email: payload.email };
            }
            if (payload.firstName) {
                customerData = { ...customerData, firstName: payload.firstName };
            }
            if (payload.lastName) customerData = { ...customerData, lastName: payload.lastName };
            if (payload.phone && payload.phone !== customerInfo?.phone) {
                customerData = { ...customerData, phone: payload.phone };
            }
            if (payload.countryCallingCode) {
                customerData = { ...customerData, countryCallingCode: payload.countryCallingCode };
            }
            if (payload.vatNumber) {
                customerData = { ...customerData, vatNumber: payload.vatNumber };
            }

            Sentry.addBreadcrumb({
                message: 'Updating customer info',
                category: 'checkout_provider',
                data: { customerData },
            });

            setCustomerInfo(customerData);
        },
        [customerInfo],
    );

    const updateShippingAddress: CheckoutContext['updateShippingAddress'] = useCallback(async (address) => {
        Sentry.addBreadcrumb({
            message: 'Updating shipping address',
            category: 'checkout_provider',
            data: {
                address,
            },
        });

        setLoading(true);
        setLoadingShippingAddress(true);
        setLoadingShippingMethods(true);
        setShippingAddress(address);
        setShippingAddressChanged(true);
        setLoadingShippingAddress(false);
        setLoading(false);
    }, []);

    const updateBillingAddress: CheckoutContext['updateBillingAddress'] = useCallback((address) => {
        Sentry.addBreadcrumb({
            message: 'Updating billing address',
            category: 'checkout_provider',
            data: {
                address,
            },
        });

        setBillingAddress(address);
    }, []);

    const updatePaymentMethod: CheckoutContext['updatePaymentMethod'] = useCallback(
        (method) => {
            if (method === paymentMethod) return;

            Sentry.addBreadcrumb({
                message: 'Switching payment method',
                category: 'checkout_provider',
                data: {
                    method,
                },
            });

            setPaymentMethod(method);
        },
        [paymentMethod],
    );

    const updateNote: CheckoutContext['updateNote'] = useCallback(
        async (payload) => {
            Sentry.addBreadcrumb({
                message: 'Updating checkout note',
                category: 'checkout_provider',
                data: { note: payload },
            });

            const newNote = {
                ...note,
                ...payload,
                // Don't update invoice address to the default address, empty instead
                invoice:
                    customerInfo?.email && payload.invoice === customerInfo?.email
                        ? ''
                        : payload.invoice?.length
                          ? payload.invoice
                          : (note.invoice ?? ''),
            };

            setNote(newNote);
        },
        [customerInfo?.email, note],
    );

    const updateCountry: CheckoutContext['updateCountry'] = useCallback(
        async (region) => {
            Sentry.addBreadcrumb({
                message: 'Updating checkout country/currency',
                category: 'checkout_provider',
                data: {
                    region,
                },
            });

            setChangingCurrency(true);
            updateNote(note);
            cartCreate({
                buyerIdentity: {
                    email: customerInfo?.email ?? customer.email,
                    phone: customerInfo?.phone,
                    deliveryAddressPreferences: [
                        { deliveryAddress: omit(shippingAddress, 'id', 'countryCode', 'provinceCode') },
                    ],
                    countryCode: region,
                    customerAccessToken: null,
                },
                lines:
                    lines?.map((line) => ({
                        merchandiseId: line?.merchandise?.id ?? '',
                        quantity: line?.quantity,
                    })) ?? [],
                discountCodes: discountCodes?.map((i) => i?.code ?? ''),
            });
            setShippingAddressChanged(true);
        },
        [
            cartCreate,
            customerInfo?.email,
            customerInfo?.phone,
            customer.email,
            shippingAddress,
            lines,
            discountCodes,
            updateNote,
            note,
        ],
    );

    const complete: CheckoutContext['complete'] = useCallback(async () => {
        if (status !== 'idle') {
            console.warn('Cart is not idle');
            return;
        }

        setLoading(true);
        setLoadingComplete(true);

        Sentry.addBreadcrumb({
            message: 'Completing checkout',
            category: 'checkout_provider',
            data: {
                lines: lines ?? [],
                shippingAddress: shippingAddress ?? {},
                selectedDeliveryOption: deliveryGroups?.at(0)?.selectedDeliveryOption ?? {},
            },
        });

        if (!lines?.length) {
            setLoading(false);
            setLoadingComplete(false);
            const error = Error('Could not complete checkout because there are no items in the cart');

            Sentry.withScope(function (scope) {
                scope.setLevel('warning');
                Sentry.captureException(error);
            });

            throw error;
        }

        if (!shippingAddress) {
            setLoading(false);
            setLoadingComplete(false);
            const error = Error('Could not complete checkout because no shipping address was selected or provided');

            Sentry.withScope(function (scope) {
                scope.setLevel('warning');
                Sentry.captureException(error);
            });

            throw error;
        }

        const selectedDeliveryOption = deliveryGroups?.at(0)?.selectedDeliveryOption;
        if (!selectedDeliveryOption) {
            setLoading(false);
            setLoadingComplete(false);
            const error = Error('Could not complete checkout because no delivery option was selected');

            Sentry.withScope(function (scope) {
                scope.setLevel('warning');
                Sentry.captureException(error);
            });

            throw error;
        }

        const shippingLine: ShippingLineInput = {
            priceWithCurrency: {
                amount: Number(selectedDeliveryOption.estimatedCost.amount),
                currencyCode: selectedDeliveryOption.estimatedCost.currencyCode as AdminCurrencyCode,
            },
            shippingRateHandle: selectedDeliveryOption?.handle,
            title: selectedDeliveryOption?.title ?? undefined,
        };

        const phone = formatPhone(customerInfo?.phone, customerInfo?.countryCallingCode);

        const draftOrder: DraftOrderInput = {
            email: customerInfo?.email,
            phone: phone.isValid ? phone.phoneNumber : undefined,
            customAttributes: [{ key: 'vat_number', value: customerInfo?.vatNumber ?? '' }],
            shippingAddress: toInputMaybeAddress(shippingAddress),
            billingAddress: toInputMaybeAddress(billingAddress),
            note: formatNote(
                { ...note, vat: customerInfo?.vatNumber },
                toInputMaybeAddress(shippingAddress).countryCode,
            ),
            lineItems: lines?.map((line) => ({
                variantId: line?.merchandise?.id,
                quantity: line?.quantity ?? 0,
                appliedDiscount: { value: 0, valueType: DraftOrderAppliedDiscountType.FixedAmount }, //TODO: implement product discounts
            })),
            appliedDiscount: discountCode
                ? {
                      title: discountCode,
                      amountWithCurrency: {
                          amount: Number(discountAllocations?.at(0)?.discountedAmount.amount),
                          currencyCode: discountAllocations?.at(0)?.discountedAmount.currencyCode as AdminCurrencyCode,
                      },
                      value: Number(discountAllocations?.at(0)?.discountedAmount?.amount) ?? 0,
                      valueType: DraftOrderAppliedDiscountType.FixedAmount,
                  }
                : undefined,
            marketRegionCountryCode: buyerIdentity?.countryCode as AdminCountryCode,
            shippingLine,
        };

        Sentry.addBreadcrumb({
            message: 'Placing order',
            category: 'checkout_provider',
            data: { draftOrder },
        });

        await mutateDraftOrder(draftOrder);

        setLoading(false);
        discountCodesUpdate([]);
        setDiscountCode(undefined);
    }, [
        billingAddress,
        buyerIdentity?.countryCode,
        customerInfo?.countryCallingCode,
        customerInfo?.email,
        customerInfo?.phone,
        customerInfo?.vatNumber,
        deliveryGroups,
        discountAllocations,
        discountCode,
        discountCodesUpdate,
        lines,
        mutateDraftOrder,
        note,
        shippingAddress,
        status,
    ]);

    const updateCheckoutAfterLogin: CheckoutContext['updateCheckoutAfterLogin'] = useCallback(
        async (customer: UseCustomerDataResult) => {
            Sentry.addBreadcrumb({
                message: 'Enriching checkout with data after login',
                category: 'checkout_provider',
                data: { customer },
            });

            updateCustomerAddressOptions(customer);
            updateCustomerInfo(customer);
            const shippingAddress =
                customer.addresses?.find(({ id }) => extractId(id) === customer.defaultShippingAddressId) ??
                customer.addresses?.at(0);
            if (shippingAddress) await updateShippingAddress(shippingAddress);

            const billingAddress =
                customer.billingAddresses?.find((address) => address.default) ?? customer.billingAddresses?.at(0);
            if (billingAddress) updateBillingAddress(billingAddress);
        },
        [updateBillingAddress, updateCustomerAddressOptions, updateCustomerInfo, updateShippingAddress],
    );

    const clearCheckout: CheckoutContext['clearCheckout'] = useCallback(async () => {
        setShippingAddressOptions([]);
        setBillingAddressOptions([]);
        setCustomerInfo(undefined);
        setShippingAddress(undefined);
        setBillingAddress(undefined);
        setShippingMethod(undefined);
        setShippingAddressChanged(true);
        [
            LocalStorageKey.SHIPPING_ADDRESS,
            LocalStorageKey.BILLING_ADDRESS,
            LocalStorageKey.USER_INFORMATION,
            LocalStorageKey.NOTE,
        ].forEach((item) => localStorage.removeItem(item));
        buyerIdentityUpdate({
            email: null,
            phone: null,
            deliveryAddressPreferences: [],
            customerAccessToken: null,
        });
    }, [buyerIdentityUpdate]);
    //#endregion

    //#region Effects
    // Fetch delivery groups on shipping address change
    useEffect(() => {
        if (
            !changingCurrency &&
            shippingAddressChanged &&
            buyerIdentity?.deliveryAddressPreferences?.[0] &&
            areEqualAddresses(buyerIdentity?.deliveryAddressPreferences?.[0], shippingAddress)
        ) {
            setShippingAddressChanged(false);
            refetchDeliveryGroups(true);
        }
    }, [
        changingCurrency,
        shippingAddressChanged,
        buyerIdentity?.deliveryAddressPreferences,
        shippingAddress,
        refetchDeliveryGroups,
    ]);

    // for clearing delivery options when logging out
    useEffect(() => {
        if (shippingAddressChanged && !shippingAddress && buyerIdentity?.deliveryAddressPreferences?.length === 0) {
            setShippingAddressChanged(false);
            refetchDeliveryGroups();
        }
    }, [buyerIdentity?.deliveryAddressPreferences, refetchDeliveryGroups, shippingAddress, shippingAddressChanged]);

    // Apply discount code from local storage
    useEffect(() => {
        if (status !== 'idle') return;
        const discountCode = localStorage.getItem(LocalStorageKey.DISCOUNT_CODE) ?? undefined;
        setDiscountCode(discountCode);
    }, [status]);

    // Set loading shipping methods on false as soon as we selected a shipping method
    useEffect(() => {
        if (loadingShippingMethods && !selectingDeliveryOption && !fetchingDeliveryGroups) {
            setLoadingShippingMethods(false);
        }
    }, [
        deliveryGroups?.length,
        fetchingDeliveryGroups,
        loadingShippingMethods,
        selectingDeliveryOption,
        shippingMethod,
    ]);

    // This should reset the changing currency state after the cart is created
    useEffect(() => {
        setChangingCurrency(false);
    }, [cartId]);

    // Discount code error effect
    useEffect(() => {
        setDiscountCodeError(false);
        if (discountCodes?.[0]?.code !== discountCode) {
            const newDiscountCode = discountCodes?.[0]?.applicable ? discountCodes?.[0].code : undefined;
            setDiscountCodeError(!discountCodes?.[0]?.applicable);
            setDiscountCode(newDiscountCode);

            newDiscountCode
                ? localStorage.setItem(LocalStorageKey.DISCOUNT_CODE, newDiscountCode)
                : localStorage.removeItem(LocalStorageKey.DISCOUNT_CODE);
        }
    }, [discountCode, discountCodes]);

    // Update buyer identity on customer info or shipping address change
    useEffect(() => {
        // If the email matches, the phone matches, and the shipping address matches (and they are set), do nothing
        if (
            buyerIdentity?.email === (customerInfo?.email ?? customer.email ?? null) &&
            buyerIdentity?.phone === formatPhone(customerInfo?.phone, customerInfo?.countryCallingCode).phoneNumber &&
            (!shippingAddress ||
                (buyerIdentity?.deliveryAddressPreferences?.[0] &&
                    areEqualAddresses(buyerIdentity?.deliveryAddressPreferences?.[0], shippingAddress)))
        )
            return;

        const newBuyerIdentity = {
            email: customerInfo?.email ?? customer.email,
            phone: formatPhone(customerInfo?.phone, customerInfo?.countryCallingCode).phoneNumber,
            deliveryAddressPreferences: shippingAddress
                ? [{ deliveryAddress: omit(shippingAddress, 'id', 'countryCode', 'provinceCode') }]
                : [],
            customerAccessToken: null,
        };

        Sentry.addBreadcrumb({
            message: 'Updating cart buyerIdentity',
            category: 'checkout_provider',
            data: {
                buyerIdentity: newBuyerIdentity,
            },
        });

        buyerIdentityUpdate(newBuyerIdentity);
    }, [
        customer.email,
        customerInfo?.email,
        customerInfo?.phone,
        customerInfo?.countryCallingCode,
        shippingAddress,
        buyerIdentity,
        buyerIdentityUpdate,
    ]);
    //#endregion

    const value: CheckoutContext = useMemo(() => {
        return {
            customerInfo,
            updateCustomerInfo,

            shippingAddressOptions,
            setShippingAddressOptions,
            shippingAddress,
            updateShippingAddress,
            setShippingAddress,

            billingAddressOptions,
            setBillingAddressOptions,
            billingAddress,
            updateBillingAddress,
            setBillingAddress,

            shippingMethod,
            updateShippingMethod,
            selectingDeliveryOption,
            deliveryGroups,
            fetchDeliveryGroups: refetchDeliveryGroups,
            note,
            updateNote,
            paymentMethod,
            updatePaymentMethod,
            discountCode,
            updateDiscountCode,
            discountCodeError,
            updateCountry,
            updateCheckoutAfterLogin,
            clearCheckout,
            complete,
            checkoutError,

            loading,
            setLoading,
            loadingShippingMethods,
            setLoadingShippingMethods,
            loadingShippingAddress,
            loadingComplete,
            setLoadingComplete,
        };
    }, [
        billingAddress,
        billingAddressOptions,
        checkoutError,
        complete,
        customerInfo,
        deliveryGroups,
        discountCode,
        discountCodeError,
        loading,
        loadingComplete,
        loadingShippingAddress,
        loadingShippingMethods,
        note,
        paymentMethod,
        refetchDeliveryGroups,
        shippingAddress,
        shippingAddressOptions,
        selectingDeliveryOption,
        shippingMethod,
        updateBillingAddress,
        updateCheckoutAfterLogin,
        clearCheckout,
        updateCountry,
        updateCustomerInfo,
        updateDiscountCode,
        updateNote,
        updatePaymentMethod,
        updateShippingAddress,
        updateShippingMethod,
    ]);

    // eslint-disable-next-line react/jsx-pascal-case
    return <_CheckoutContext.Provider value={value}>{children}</_CheckoutContext.Provider>;
}

export const useCheckout = () => useContext(_CheckoutContext);
