import axios from 'axios';

import { DraftOrderInput } from '@lib/machine-parts/types/graphql/admin';
import { useMutation } from '@tanstack/react-query';

const url = '/api/cart/create-order';

type MakeCreateDrafOrderResponse = {
    orderId: string;
    errors: string | undefined;
};

export type CreateDraftOrderMutationArgs = DraftOrderInput;

export function useCreateOrderMutation({
    onSuccess,
    onError,
}: {
    onSuccess?: (success: { orderId: string }, args: CreateDraftOrderMutationArgs) => void;
    onError?: (error?: string, args?: CreateDraftOrderMutationArgs) => void;
}) {
    return useMutation(
        ['create-order'],
        async (draftOrder?: CreateDraftOrderMutationArgs): Promise<{ orderId: string }> => {
            const { data } = await axios.post<MakeCreateDrafOrderResponse>(url, {
                draftOrder,
            });

            if (data.errors) {
                throw new Error(data.errors);
            }

            return { orderId: data.orderId };
        },
        {
            onSuccess,
            onError,
        },
    );
}
