import axios from 'axios';

import { CartSelectedDeliveryOptionInput } from '@shopify/hydrogen-react/storefront-api-types';
import { useMutation } from '@tanstack/react-query';

const url = '/api/cart/select-delivery-options';

type MakeSelectDeliveryOptionsResponse = { cartId?: string; error?: string };

export type MakeSelectDeliveryOptionsMutationArgs = {
    cartId: string;
    selectedDeliveryOptions: CartSelectedDeliveryOptionInput[];
};

export function useSelectDeliveryOptionsMutation({
    onSuccess,
    onError,
}: {
    onSuccess?: (deliveryOptionHandle: string, args: MakeSelectDeliveryOptionsMutationArgs) => void;
    onError?: (error?: string, args?: MakeSelectDeliveryOptionsMutationArgs) => void;
}) {
    return useMutation(
        ['select-delivery-options'],
        async ({ cartId, selectedDeliveryOptions }: MakeSelectDeliveryOptionsMutationArgs): Promise<string> => {
            const { data } = await axios.post<MakeSelectDeliveryOptionsResponse>(url, {
                cartId,
                selectedDeliveryOptions,
            });

            if (!data.cartId) {
                throw new Error(`No cartId returned`);
            }

            if (data.error) {
                throw new Error(data.error);
            }

            return selectedDeliveryOptions[0]?.deliveryOptionHandle;
        },
        {
            onSuccess,
            onError,
        },
    );
}
