import { Path, UseFormReturn } from 'react-hook-form';

import { AddressForm } from '@lib/machine-parts/storefront/account/utils';
import { ShippableCountry } from '@lib/machine-parts/storefront/utils';

import { UserInfoForm } from './checkoutForm';

interface SetValuesUserInfoFromLocalStorageProps {
    localUserInformation: UserInfoForm;
    formMethods: UseFormReturn<UserInfoForm>;
    locale: ShippableCountry;
}

export async function setUserInfoValuesFromLocalStorage({
    localUserInformation,
    formMethods,
}: SetValuesUserInfoFromLocalStorageProps) {
    const { email, firstName, lastName, vat, phone } = localUserInformation;
    if (email) {
        formMethods.setValue('email', email, { shouldDirty: true });
    }

    if (firstName) {
        formMethods.setValue('firstName', firstName, { shouldDirty: true });
    }

    if (lastName) {
        formMethods.setValue('lastName', lastName, { shouldDirty: true });
    }

    if (vat) {
        formMethods.setValue('vat.countryCode', vat.countryCode as ShippableCountry, {
            shouldDirty: true,
        });
        formMethods.setValue('vat.vatNumber', vat.vatNumber, { shouldDirty: true });
    }

    if (phone) {
        formMethods.setValue('phone.countryCallingCode', phone.countryCallingCode, { shouldDirty: true });
        formMethods.setValue('phone.phone', phone.phone, {
            shouldDirty: true,
        });
    }
}

interface SetAddressValuesFromLocalStorageProps {
    localCheckoutAddress: AddressForm;
    formMethods: UseFormReturn<AddressForm>;
}

export async function setAddressValuesFromLocalStorage({
    localCheckoutAddress,
    formMethods,
}: SetAddressValuesFromLocalStorageProps) {
    const addressFormFields: (keyof AddressForm)[] = [
        'city',
        'countryCode',
        'company',
        'address1',
        'countryCallingCode',
        'phone',
        'zip',
    ];
    for (const field of addressFormFields) {
        const value = localCheckoutAddress?.[field];
        if (value) {
            formMethods.setValue(field as Path<AddressForm>, String(value), { shouldDirty: true });
        }
    }
    await formMethods.trigger(addressFormFields);
}
